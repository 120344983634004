<template>
  <div class="col-12">
    <h2>تقارير البيانات التراكمية حتى تاريخ
        {{ date }}
    </h2>

    <table>
        <tr>
            <th class="r">#</th>
            <th class="r">إسم المعلم</th>
            <th class="r">المادة</th>
            <th><span>الطلاب</span></th>
            <th><span>الاثراءات</span></th>
            <th><span> الأسئلة</span></th>
            <th><span> المسارات المنشأة</span></th>
            <th><span> المسارات المنشورة</span></th>
            <th><span> المسارات المعتمدة</span></th>
            <th><span>الفصول</span></th>
            <th><span> الدروس المتزامنة</span></th>
            <th><span> الدروس غير المتزامنة</span></th>
            <th><span>الواجبات</span></th>
            <th><span>الواجبات المنشورة</span></th>
            <th><span>واجبات الحصص</span></th>
            <th><span>واجبات منشورة مصححة</span></th>
            <th><span>واجبات الحصص المصححة</span></th>
            <th><span> الإختبارات</span></th>
            <th><span>الإختبارات المنشورة</span></th>
            <th><span>إختبارات الحصص</span></th>
            <th><span>الأنشطة</span></th>
            <th><span>الأنشطة المنشورة</span></th>
            <th><span>أنشطة الحصص الاساسية</span></th>
        </tr>
        <tbody id="CUVgKWwm9aLRHRQB"></tbody>
    </table>
    <div class="ajax"></div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            date: new Date().toISOString().split("T")[0]
        }
    },
    created(){
        var g = this;
        var naqar_get = function(t){
        return new Promise((resolveOuter) => {
            $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
            var i111 = setInterval(function(){
                if($(".get-local-storage-value[name='"+t+"']").length){
                    clearInterval(i111)
                    resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
                }
            })
        });
        }
        naqar_get("teachersReportsHTML").then(function(data){
            JSON.parse(data).forEach(function(teacher, index){
                 if (index % 2 == 0) {
                    var color = "#f5f4f4";
                } else {
                    var color = "";
                }
                $("#CUVgKWwm9aLRHRQB").append(`
                <tr style='background-color: ` + color + `'>
                    <td rowspan="` + ($(teacher.data).find("h3").length + 1) + `">` + (index+1) + `</td>
                    <td rowspan="` + ($(teacher.data).find("h3").length + 1) + `">` + teacher.name + `</td>
                </tr>
                `);

                $(teacher.data).find("h3").each(function () {
                    
                        var g = $(this).parent()
                        var title = $("h3", g).text()
                        g = g.parent()
                        var subject = title.split("-")[3] + " <br> " + title.split("-")[2];
                        if (title.split("-")[4] != undefined) {
                            subject = title.split("-")[4] + " <br> " + subject;
                        }
                        $("#CUVgKWwm9aLRHRQB").append(`
                        <tr class='tqq' style='background-color: ` + color + `'>
                            <td class='r'>` + subject.replace("undefined", '') + `</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(2) > ul > li:nth-child(4) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(2) > ul > li:nth-child(5) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(3) > ul > li:nth-child(1) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(2) > ul > li:nth-child(1) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(2) > ul > li:nth-child(2) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(2) > ul > li:nth-child(3) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(3) > ul > li:nth-child(2) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(3) > ul > li:nth-child(3) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(3) > ul > li:nth-child(4) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(3) > ul > li:nth-child(5) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(4) > ul > li:nth-child(1) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(4) > ul > li:nth-child(2) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(4) > ul > li:nth-child(4) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(4) > ul > li:nth-child(5) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(5) > ul > li:nth-child(1) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(5) > ul > li:nth-child(2) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(5) > ul > li:nth-child(3) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(6) > ul > li:nth-child(1) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(6) > ul > li:nth-child(6) > span').text()}</td>
                            <td>${g.find('div:nth-child(2) > div:nth-child(6) > ul > li:nth-child(4) > span').text()}</td>
                        </tr>
                        `);

                    });
            })

            $("#CUVgKWwm9aLRHRQB").append(`
        <tr>
            <td></td>
            <td></td>
            <td><strong>مجموع بيانات التقرير</strong></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
        </tr>
        <style>
        .grr{
            font-style: bold
        }
        </style>
        `);

            setTimeout(() => {

                $(".grr").each(function () {
                    var count = 0

                    g = $(this)

                    $(".tqq").each(function () {
                        count = count + new Number($(this).find("td:nth-child(" + (g.index() - 1) + ")").text())
                    })

                    $(this).html(count)

                })

                window.print();

            }, 1000);

        });

    }
}
</script>

<style>



        body {
            background-color: #fff;
            padding-top: 120px;
        }
        * {
            white-space: nowrap;
        }

        table,
        td,
        th {
            border: 1px solid black;
            padding: 3px;
            white-space: nowrap;
            border-top: none;
        }

        table {
            width: 99% !important;
            border-collapse: collapse;
            margin: 0px auto;
        }

        th.r {
            border-top: 1px solid black;
        }

        td:not(.r) {
            padding-right: 15px;
            padding-left: 10px;
        }

        th:not(.r) {
            position: relative;
        }

        th:not(.r) span {
            position: absolute;
            transform: rotate(-70deg);
            bottom: 15px;
            left: 0px;
            width: 100%;
            height: 100%;
            direction: ltr;
            border-top: 1px solid black;
            border-radius: 7px 0px 0px 0px;
        }

        nsc,
        .ajax,
        footer {
            display: none;
        }
</style>